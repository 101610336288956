import { ExpandableSection, SubSection } from "./components";
import { charts } from "../constants";
import { ChartProvider, useCurrentSelection } from "../../../../contexts";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import clsx from "clsx";

export default function Panel({ openModal }) {
  const { currentSelection } = useCurrentSelection();
  return (
    <div
      id="jmrvPanel"
      className={"z-20 w-full"}
      style={{ scrollbarGutter: "stable" }}
    >
      {currentSelection.chartData ? (
        charts.map((chart) => {
          const subSectionProps = {
            description: chart.description,
            breakdownTitle: chart.breakdownTitle,
            breakdownSubtitle: chart.breakdownSubtitle,
            colors: chart.colors,
            category: chart.category,
          };
          return (
            <ExpandableSection
              title={chart.title}
              openModal={openModal}
              modalContent={chart.modalContent}
            >
              <ChartProvider product={chart.category} type={chart.chartType}>
                <SubSection {...subSectionProps} />
              </ChartProvider>
            </ExpandableSection>
          );
        })
      ) : (
        <SkeletonTheme color="#e0e0e0" highlightColor="#c0c0c0">
          <Skeleton width={410} height={60} count={4} className="mb-2" />
        </SkeletonTheme>
      )}
    </div>
  );
}

const containerStyle = clsx(
  "z-50 h-full w-full overflow-y-auto overflow-x-hidden bg-white lg:absolute lg:h-auto lg:max-h-screen lg:w-fit lg:bg-transparent lg:bg-none lg:p-4 lg:pr-2"
);
