import clsx from "clsx";
export default function Legend({ legendItems }) {
  return (
    <div className={containerStyle}>
      {Object.keys(legendItems).map((key) => (
        <div key={key} className="flex items-center mr-4 last:mr-0">
          <div
            style={{ backgroundColor: legendItems[key].color }}
            className="w-3 h-3"
          />
          <div className={titleStyle}>{legendItems[key].display}</div>
        </div>
      ))}
    </div>
  );
}

const titleStyle = clsx(
  "opacity-90 text-black text-sm leading-tight ml-1"
);
const containerStyle = clsx(
  "w-full pb-4 flex flex-wrap justify-center items-center"
);
