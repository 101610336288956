import { useState } from "react";
import clsx from "clsx";

import Button from "../../../../Modal/components/Button";
import UpArrow from "../../../../../../assets/up-arrow.svg";
import DownArrow from "../../../../../../assets/down-arrow.svg";

export default function ExpandableSection(props) {
  const { title, children, openModal, modalContent } = props;
  const [expanded, setExpanded] = useState(false);

  const toggleSection = () => {
    setExpanded(!expanded);
  };

  return (
    <div key={title} className={expandParentStyle}>
      <div className={expandToggleStyle} onClick={toggleSection}>
        <div className="inline-flex">
          <div className="font-bold text-lg">{title}</div>
          <Button
            openModal={openModal}
            modalContent={modalContent}
            icon={"i"}
          />
        </div>
        <button onClick={toggleSection}>
          {expanded ? (
            <img src={DownArrow} alt="open subsection" />
          ) : (
            <img src={UpArrow} alt="closed subsection" />
          )}
        </button>
      </div>
      <div className={`content ${expanded ? expandedStyle : contractedStyle}`}>
        {children}
      </div>
    </div>
  );
}

const expandParentStyle = clsx(
  "w-full h-fit lg:w-[410px] bg-white p-4 mb-2 last:mb-4 rounded-lg shadow-md"
);
const expandToggleStyle = clsx(
  "flex justify-between items-center cursor-pointer"
);
const expandedStyle = clsx(
  "opacity-100 max-h-auto transition-max-height duration-500 ease-in-out"
);
const contractedStyle = clsx(
  "opacity-0 max-h-0 overflow-hidden transition-max-height duration-500 ease-in-out"
);
