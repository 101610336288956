import { MultiRangeSlider, Legend, ChartFilter } from "./components";
import LineChart from "./LineChart";
import BarChart from "./BarChart";
import * as contexts from "../../../../../../contexts";
import { emissionLineColors, otherLineColors, legendItems } from "./constants";
import { panelDisplayTitles } from "../../../constants";

export default function ChartSection({ type }) {
  const { currentChart } = contexts.useCurrentChart();
  const { currentSelection } = contexts.useCurrentSelection();

  const { category, xAxisLimits, units } = currentChart;
  const { chartData, displayText } = currentSelection;

  return (
    <>
      <MultiRangeSlider id={category} />
      <Legend legendItems={legendItems[category]} />
      <div className="text-sm mb-1 w-full text-center">
        {panelDisplayTitles[category]} in <span className="font-bold">{displayText}</span>
      </div>
      <div className="relative w-full content-center">
        <ChartFilter
          data={chartData[category]}
          xAxisLimits={xAxisLimits}
          chartType={type}
        >
          {(data, xAxisLimits) =>
            type === "bar" ? (
              <BarChart id={category} />
            ) : (
              <LineChart
                id={category}
                multiLineData={data}
                unit={units}
                xAxisLimits={xAxisLimits}
                lineColors={
                  category === "emission" ? emissionLineColors : otherLineColors
                }
              />
            )
          }
        </ChartFilter>
      </div>
    </>
  );
}
